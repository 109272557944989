/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */
body {
	font-family: 'Verdana', 'Tahoma', 'Roboto', sans-serif;
	background: none;
	background-color: #F3FBFF;

}
hr {
    border: 0;
    border-top: solid 1px _palette(border);
    margin-bottom: 1.5em;
    }

div {
	
}
#header {
	padding-top: 1em;
}
#logo-wrapper > h1 {
	padding-bottom: 1em;
}
#cta {
	padding: 1em 0;
}
#cta h2 {
	font-size: 1.15em;
}
.style1 h2 {
	text-align: center;
}
#footer {
	background-color: #CF2E2E;
	color: #D6F2FE;
	padding: 4em 0 2em 0;

}
#footer .icons a {
	color: #D6F2FE;
}
#footer .copyright li {
	color: #D6F2FE;
}
#banner {
	height: 20em;
}
article > p > img {
	max-width: 300px;
}
blockquote {
	border-left: 10px solid #ccc;
	margin: 1.5em 10px 1.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201c""\201D""\2018""\2019"
}
blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}
blockquote footer:after {
	color: #ccc;
	content: close-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-left: 0.25em;
	vertical-align: -0.4em;
	display: block;
	float: right;
}
blockquote footer:before {
	color: #ccc;
	content: "\2015";
	margin-right: 0.25em;
}
blockquote footer {
	float: right;
	margin-bottom: 1em;
}
blockquote.p {
	display: inline;
}
.clearfix:after {
	content: "";
	display: block;
	clear: both;
}
.sidebar-left {
	border-color: _palette(accent, bg);
	border-right: 2px solid;
	padding-right: 2em;
	color: _palette(fg);
}
.sidebar-left .panel-title {
	text-align: center;
}
.icon-tabler-external-link {
	height: 1.25em;
	padding-bottom: 0;
	margin-bottom: -0.2em;

}
.gallery {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	}
.gallery > img {
	max-width: 250px;
	padding: 5px;
	}
a {
	color: _palette(fg-bold);
	}

blockquote {
	padding: 1em 0 0 1em;
}
.tag-cloud {
	display: flex;
	display: -webkit-flex;
	flex-flow: row wrap;
	-webkit-flex-flow: row wrap;
	list-style: none;
} 
.tag-cloud li {
	-webkit-flex: 1 auto;
	flex: 1 auto;
	padding: 0.5rem;
	text-align: center
}
.tag-cloud a {
	display: block;
	font-weight: bold;
	text-transform: lowercase;
}
.small {
	font-size: smaller;
	font-style: italic;
}
.updates > h5.small {
	font-size: smaller;
	font-style: italic;
}
.bookTitle {
	font-style: italic;
}
ul > li > ul {
	margin-bottom: 0;
}
.nobreak code {
	display: inline-block;
}
.antipixel {
  display: inline;
  margin: 4px;
  border: 2px;
  padding: 0px;
  border-style: solid;
  border-color: #666666;
  background-color: white;
  font-size: x-small;
  width: 80px;
  min-width: 80px;
  height: 15px;
  min-height: 15px;
  max-width: 80px;
  max-height: 15px;
  font-family: sans-serif;
  overflow-y: hidden;
}
a.antipixel {
  text-decoration: none;
  }
a.antipixel:hover {
	color: #4c4c4c;
	border-bottom-color:#666666 ;
	box-shadow: 5px 2px 2px #666666;
}

.antipixel > span:nth-of-type(1) {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0.5px;
  border: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
}
.antipixel > span:nth-of-type(2) {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0.5px;
  margin-right: 0px;
  border: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
}
.antipixel.hugo > span:nth-of-type(1) {
	color: #FFFFFF;
	background-color: #CD336D;
}
.antipixel.hugo > span:nth-of-type(2) {
	color: black;
}
.antipixel.notrack > span:nth-of-type(1) {
	color: white;
	background-color: black;
}
.antipixel.notrack > span:nth-of-type(2) {
	color: black;
}
.antipixel.no-ai > span:nth-of-type(1) {
	color: black;
}
.antipixel.no-ai > span:nth-of-type(2) {
	color: white;
	background-color: black;
}
@media (prefers-reduced-motion) {
  a.antipixel:hover {
  	box-shadow: none;
  }
}
